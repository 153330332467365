import { Application } from "@hotwired/stimulus"
const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application

export { application }

import FlashController from "./flash_controller.js"
application.register("flash", FlashController)

import ConfettiController from "./confetti_controller.js"
application.register("confetti", ConfettiController)

import NestedFormController from "./nested_form_controller.js"
application.register("nested-form", NestedFormController)

import ClipboardController from "./clipboard_controller"
application.register("clipboard", ClipboardController)
