// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import "@rails/activestorage"

import "./controllers"
import "chartkick/chart.js"

import GLightbox from 'glightbox'
import * as bootstrap from "bootstrap"

document.addEventListener("turbo:load", () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

  glightbox()
})

// this initializes glightbox on page load with turbo frames enable
document.addEventListener("turbo:frame-render", () => {
  glightbox()
})

document.addEventListener("turbo:request-start", function (event) {
  var xhr = event.data.xhr;
  xhr.setRequestHeader("X-Turbo-Nonce", $("meta[name='csp-nonce']").prop('content'));
})

function glightbox() {
    const lightbox = GLightbox({
    selector: '.glightbox',
    zoomable: false,
    draggable: false
    })
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      registration.addEventListener("updatefound", () => {
        // If updatefound is fired, it means that there's
        // a new service worker being installed.
        const installingWorker = registration.installing;
        console.log(
          "A new service worker is being installed:",
          installingWorker,
        );

        // You can listen for changes to the installing service worker's
        // state via installingWorker.onstatechange
      });
    })
    .catch((error) => {
      console.error(`Service worker registration failed: ${error}`);
    });
} else {
  console.error("Service workers are not supported.");
}
